import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { useAuth } from '../../contexts/auth';
import * as XLSX from 'xlsx';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SearchDD from '../SearchDD';
import FlexRow from '../layout/FlexRow';
//import MultipleValueTextInput from 'react-multivalue-text-input';
import TagsInputList from '../tagAdder';
import ReactJson from 'react-json-view';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, IconButton, Tooltip, Chip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const UserIdTokenTable = () => {
    const [keyValue, setKeyValue] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [buttonDisability, setButtonDisability] = useState(false);
    const [selected, setSelected] = useState([]);
    const [columnList, setColumnList] = useState([
        { label: 'User Id', id: 'user_ids' },
        //{ label: 'Token', id: 'tokens' },
        { label: 'Phone Number', id: 'phone_numbers' },
    ]);
    const [columnName, setColumnName] = useState(columnList[0]);
    const [column, setColumn] = useState([]);
    var csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    };
    const [loading, setLoading] = useState(false);
    const axios = useAxios();
    const { isAdmin, isOwner, isAdminApprover, isPIIDataViewerLimitExeeded} = useAuth();
    const owner = isOwner();
    const adminApprover = isAdminApprover();
    const piiDataViewerLimitExeeded = isPIIDataViewerLimitExeeded()
    const [checked, setChecked] = useState(false);
    const [value, setValue] = useState(10);
    const [emails, setEmails] = useState([]);

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    function handleDelete(item) {
        var values = emails;
        values.splice(emails.indexOf(item), 1);
        setEmails(values);
    }

    //console.log(selected);
    useEffect(() => {
        var data = [];
        var ide = columnName?.id;
        if (ide === 'user_ids') {
            for (var i = 0; i < selected.length; i++) {
                data.push({ user_ids: selected[i] });
            }
        } else if (ide === 'tokens') {
            for (var i = 0; i < selected.length; i++) {
                data.push({ tokens: selected[i] });
            }
        } else {
            for (var i = 0; i < selected.length; i++) {
                data.push({ phone_numbers: selected[i] });
            }
        }
        setTableData(data);
    }, [selected]);

    useEffect(() => {
        setColumn([
            {
                accessorKey: columnName?.id,
                header: columnName?.label,
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
        ]);
    }, [columnName]);

    function getPIIData() {
        if (tableData.length == 0) {
            alert('Please insert atleast one userId');
            return;
        }
        if (!piiDataViewerLimitExeeded && tableData.length > 1000) {
            alert("You don't have enough permission to process more than 1000 data points");
            return;
        }
        if (piiDataViewerLimitExeeded && tableData.length > 50000) {
            alert('Cannot process more than 50000 data points');
            return;
        }
        if (checked && columnName === null) {
            alert('Please choose key corresponding to which you want PII data');
            return;
        }
        setLoading(true);
        setButtonDisability(true);
        var dataList = [];
        if (checked) {
            for (var i = 0; i < tableData.length; i++) {
                dataList.push(tableData[i][column[0]?.accessorKey]);
            }
        } else {
            for (var i = 0; i < selected.length; i++) {
                dataList.push(selected[i]);
            }
        }
        var data = {};
        var requestOptions = {};
        if (columnName?.id === 'user_ids') {
            data['user_ids'] = dataList;
            data['phone_numbers'] = [];
            requestOptions = {
                url: `${BACKEND_URL}/user/apna-token-details-from-users/`,
                method: 'POST',
                data: data,
            };
        } else if (columnName?.id === 'tokens') {
            data['tokens'] = dataList;
            requestOptions = {
                url: `${BACKEND_URL}/user/apna-user-details-from-tokens/`,
                method: 'POST',
                data: data,
            };
        } else {
            data['phone_numbers'] = dataList;
            data['user_ids'] = [];
            requestOptions = {
                url: `${BACKEND_URL}/user/apna-token-details-from-users/`,
                method: 'POST',
                data: data,
            };
        }
        axios(requestOptions).then(function (res) {
            //console.log(res.data);
            if (res.data.length > 0) {
                var columns = [];
                var keyValues = Object.keys(res.data[0]);
                var keys = [];
                if (keyValues.includes('user_id')) {
                    keys.push('user_id');
                    var index = keyValues.indexOf('user_id');
                    keyValues.splice(index, 1);
                }
                if (keyValues.includes('user_name')) {
                    keys.push('user_name');
                    var index = keyValues.indexOf('user_name');
                    keyValues.splice(index, 1);
                }
                if (keyValues.includes('user_email')) {
                    keys.push('user_email');
                    var index = keyValues.indexOf('user_email');
                    keyValues.splice(index, 1);
                }
                if (keyValues.includes('user_phone')) {
                    keys.push('user_phone');
                    var index = keyValues.indexOf('user_phone');
                    keyValues.splice(index, 1);
                }
                if (keyValues.includes('is_internal_user')) {
                    keys.push('is_internal_user');
                    var index = keyValues.indexOf('is_internal_user');
                    keyValues.splice(index, 1);
                }
                /*if (keyValues.includes('tokens')) {
                    keys.push('tokens');
                    var index = keyValues.indexOf('tokens');
                    keyValues.splice(index, 1);
                }
                console.log(keyValues);*/
                for (var i = 0; i < keyValues.length; i++) {
                    if (keyValues[i] !== 'tokens') {
                        keys.push(keyValues[i]);
                    }
                }
                setKeyValue(keys);
                for (var i = 0; i < keys.length; i++) {
                    if (keys[i] === 'tokens') {
                        columns.push({
                            accessorKey: keys[i],
                            header: keys[i],
                            size: 140,
                            Cell: ({ cell }) => (
                                <ReactJson
                                    collapseStringsAfterLength={80}
                                    src={cell.getValue()}
                                    displayDataTypes={false}
                                    name={false}
                                />
                            ),
                            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                                ...getCommonEditTextFieldProps(cell),
                            }),
                        });
                    } else if (keys[i] === 'is_internal_user') {
                        columns.push({
                            accessorKey: keys[i],
                            header: keys[i],
                            size: 140,
                            Cell: ({ cell }) => (
                                <>{cell.getValue()?.toString()}</>
                            ),
                            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                                ...getCommonEditTextFieldProps(cell),
                            }),
                        });
                    } else {
                        columns.push({
                            accessorKey: keys[i],
                            header: keys[i],
                            size: 140,
                            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                                ...getCommonEditTextFieldProps(cell),
                            }),
                        });
                    }
                }
                setColumn(columns);
                var table = [];
                for (var i = 0; i < res.data.length; i++) {
                    var dataPoint = {};
                    for (var j = 0; j < keys.length; j++) {
                        dataPoint[keys[j]] = res.data[i][keys[j]];
                    }
                    table.push(dataPoint);
                }
                setTableData(table);
                setLoading(false);
            }
        });
    }

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode();
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue(
                        columnName?.id
                    )}`
                )
            ) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const processData = (dataString) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(
            /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
        );

        const list = [];
        for (let i = 1; i < Math.min(dataStringLines.length, 100001); i++) {
            const row = dataStringLines[i].split(
                /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
            );
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"') d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }
                if (Object.values(obj).filter((x) => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        setColumn(
            headers.map((c) => ({
                accessorKey: c,
                header: c,
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            }))
        );
        setTableData(list);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert keyValues of keyValuess */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        var tableValue = [];
        for (var i = 0; i < tableData.length; i++) {
            var dataPoint = {};
            for (var j = 0; j < keyValue.length; j++) {
                if (keyValue[j] === 'tokens') {
                    dataPoint['tokens'] = JSON.stringify(
                        tableData[i]['tokens']
                    );
                } else {
                    dataPoint[keyValue[j]] = tableData[i][keyValue[j]];
                }
            }
            tableValue.push(dataPoint);
        }
        console.log(tableValue);
        csvExporter.generateCsv(tableValue);
    };

    return (
        <>
            <FlexRow>
                <SearchDD
                    label={'Select key'}
                    emptyLabel={'Select key'}
                    options={columnList}
                    value={columnName}
                    onChange={setColumnName}
                    disabled={buttonDisability}
                />
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
            </FlexRow>
            <FlexRow>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={buttonDisability}
                                    color="secondary"
                                    checked={checked}
                                    onChange={handleCheck}
                                    inputProps={{
                                        'aria-label': 'controlled',
                                    }}
                                />
                            }
                            label={
                                <Chip
                                    label={'UPLOAD CSV'}
                                    style={{ fontWeight: 'bold' }}
                                    color={
                                        buttonDisability ? 'default' : 'error'
                                    }
                                />
                            }
                            labelPlacement="start"
                        />
                    </FormGroup>
                </FormControl>
                <input
                    size={400}
                    disabled={buttonDisability || !checked}
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    onChange={handleFileUpload}
                />
                <FlexRow></FlexRow>
            </FlexRow>
            <FlexRow>
                <TagsInputList
                    disabled={checked}
                    label={'Label'}
                    width={'1400px'}
                    selected={selected}
                    setSelected={setSelected}
                    placeHolder={`Enter ${columnName?.id}`}
                />
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
            </FlexRow>
            <FlexRow>
                <Button
                    style={{
                        width: '500px',
                        height: '40px',
                        fontWeight: 'bold',
                    }}
                    color="warning"
                    onClick={getPIIData}
                    variant="contained"
                    disabled={buttonDisability}
                >
                    Get Details
                </Button>
            </FlexRow>
            <Alert style={{marginBottom:'10px', marginTop:'10px'}}>
                Please click "Enter" after entering Phone Numbers/User IDs. Then only it will get considered.
            </Alert>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        size: 120,
                    },
                }}
                columns={column}
                data={tableData}
                editingMode="modal" //default
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                state={{
                    isLoading: loading,
                }}
                initialState={{ density: 'compact' }}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={buttonDisability}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                                disabled={buttonDisability}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            zIndex: 5,
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            style={{
                                width: '200px',
                                height: '31px',
                                fontWeight: 'bold',
                            }}
                            color="success"
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
            />
        </>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default UserIdTokenTable;
